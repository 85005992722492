import { INavData } from '@coreui/angular';
import { ActivatedRoute } from '@angular/router';

export const navItems: INavData[] = [
     {
    name: 'Dashboard ',
    url: '/dashboard',
   
  },
    {
    name: 'Google ads ',
    url: '/google/ads',
   
  },{
    name: 'Facebook ',
    url: '/facebook',
   
  },
{
    name: 'Assessments Statistics ',
    url: '/general/assessmentsstatistics',
   
  },
    {
    name: 'Customer Statistics',
    url: '/general/customerstatistics',
   
  },{
    name: 'Doctors Letter',
    url: '/general/doctorsletter',
   
  },{
    name: 'Free Evaluation Statistics ',
    url: '/general/freeevaluationstatistics',
   
  },{
    name: 'Free Evalutation to Patient Statistics',
    url: '/general/freeevalutationpatientstatistics',
   
  },{
    name: 'Lead Booking Statistics ',
    url: '/general/leadbookingstatistics',
   
  },{
    name: 'Lead Source Reports ',
    url: '/general/leadsourcereports',
   
  },{
    name: 'Leads to Free Evaluation Reports ',
    url: '/general/leadsfreeevaluationreports',
   
  },{
    name: 'Programwise Statistics',
    url: '/general/programwisestatistics',
   
  },{
    name: 'To do List Statistics ',
    url: '/general/todoliststatistics',
   
  },{
    name: 'Assessment Letters Reports',
    url: '/general/assessmentlettersreports',
   
  },{
    name: 'Total Patients Statistics ',
    url: '/general/totalpatientsstatistics',
   
  },{
    name: 'Visit Type Statistics ',
    url: '/general/visittypestatistics',
   
  }
    
    
  

];
