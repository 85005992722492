<div class="modal  fade  d-block {{showclass}}" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" *ngIf="ishow">
  <div class="modal-dialog {{Modalsize}}   modal-dialog-scrollable" role="document">
    <div class="modal-content ">
      <div class="modal-header border-0" >
          
        <h5 class="modal-title text-primary" id="exampleModalLabel" *ngIf="title!=''">{{title}}</h5>
        <button type="button" class="close" data-dismiss="modal" (click)="hidemodal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body table-responsive text-center" [innerHTML]="content" >
   
         
      </div>
     
    </div>
  </div>
</div>
<div class="modal-backdrop fade show" *ngIf="ishow"></div>
