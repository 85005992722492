import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';


const TOKEN_HEADER_KEY = 'Authorization';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

 constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
       let authReq = request;

const clinictoken =localStorage.getItem("reportauth");
const clinicadmintoken =localStorage.getItem("reportadminauth");
      if(clinictoken){
          authReq = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + clinictoken)});
         }
      if(clinicadmintoken){
          authReq = request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + clinicadmintoken)});
         }


    return next.handle(authReq);
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
