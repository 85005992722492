<div class="row justify-content-center mb-4">
<div class="col-md-5">
    <div class="card">
    <div class="card-body text-center">
        
        <h1>Welcome to Get Back Reporting</h1>
        </div>
    </div>
    
    </div>
</div>

<div *ngIf="isAdmin==1">
<div class="row justify-content-center">
<div class="col-md-5">
    
    <div class="card">
        <div class="card-header">
        <h5 class="card-title m-0">
            Clinic 
            </h5>
        </div>
    <div class="card-body">
        <div class="form-group">
        <select class="custom-select" [(ngModel)]="selectedClinic">
            <option [ngValue]="{Name:'',Id:''}">Select Clinic</option>
            <option [ngValue]="{'Name':'ALLCLINIC',Id:'0'}">All Clinic</option>
            <option *ngFor="let clinic of cliniclist" [ngValue]="clinic">{{clinic.Name}}</option>
            </select>
        
        </div>
        
         <div class="form-group text-center">
             <button type="button" class="btn btn-warning" (click)="loadclinic($event)">Submit</button>
        </div>
        
        </div>
    </div>
    </div>
</div>
</div>