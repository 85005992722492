import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightmodalComponent } from './rightmodal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  declarations: [RightmodalComponent],
  imports: [
    CommonModule,
       ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
      FormsModule,
      BrowserModule,
  ], exports: [RightmodalComponent],
     schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class RightmodalModule { }
