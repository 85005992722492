import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Popup } from './popup';
@Injectable({
  providedIn: 'root'
})
export class RmodalService {

  private subject = new Subject<Popup>();
  constructor() { }
  onPopUp():Observable<Popup>{
       return this.subject;
  }
  show(cont:string,title:string,size:string){
return new Promise((resolve,reject)=>{
 this.subject.next(new Popup({show:true,html: cont,hr:title,size:size}));
    setTimeout(()=>{
        
        resolve();
    },500);
    
});
  
  }
  hide(){
 
  this.subject.next(new Popup({show:false}));
  }
}
