import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { AuthGuard } from './helper/auth.guard';
import { LoginComponent } from './views/login/login.component';

export const routes: Routes = [
{
    path: '',
   redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: DefaultLayoutComponent,
     
     children: [
         {
    path: 'dashboard',
    component: LoginComponent,
    data: {
      title: 'Dashboard'
    }
  },
     {
        path: 'general',
          
         loadChildren: () => import('./views/general/general.module').then(m => m.GeneralModule)
      },{
        path: 'google',
          
         loadChildren: () => import('./views/googlereports/googlereports.module').then(m => m.GooglereportsModule)
      },{
        path: 'facebook',
          
         loadChildren: () => import('./views/facebookreports/facebookreports.module').then(m => m.FacebookreportsModule)
      },{
        path: 'admin',
          
         loadChildren: () => import('./views/superadmin/superadmin.module').then(m => m.SuperadminModule)
      }
      
      
    ]
  },{
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  }

];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
