import { Component, OnInit,ViewEncapsulation,ElementRef,ViewChild,Input, Output,EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable,throwError } from 'rxjs';
import { Subscription } from 'rxjs';
import { RmodalService } from './rmodal.service';

declare var $: any;
@Component({
  selector: 'app-rightmodal',
  templateUrl: './rightmodal.component.html',
  styleUrls: ['./rightmodal.component.scss']
})
export class RightmodalComponent implements OnInit {
 subscription: Subscription;
 ishow:boolean;
    content:string;
    title:string;
    showclass:string;
    Modalsize:string;
    seletype:string='';
         SpecialtysVal:string='';
	
 private dataSpecialtySource = new  BehaviorSubject < any | undefined > (undefined);
  Specialtydata = this.dataSpecialtySource.asObservable();
    
      @ViewChild("specInput", {
        static: false
    }) specInput: ElementRef;
    
     specsearch=false;
   speccompleted=false;
      submitted = false;
    
      _timeoutSpecialty: any = null;

      @Output() onSuggest: EventEmitter<any> = new EventEmitter();
  constructor(private POPS: RmodalService,private _eref: ElementRef) { }
  
  ngOnInit() {
       this.ishow=false;
      
  this.subscription=this.POPS.onPopUp().subscribe(Isshow=>{
  
    this.ishow=Isshow.show;
      this.content=Isshow.html;
      this.title=Isshow.hr;
      this.Modalsize=(Isshow.size!="")?"modal-"+Isshow.size:"";
      document.body.classList.add("modal-open");
      setTimeout(()=>{
          this.showclass="show";
      },100)
  });
      
       
  }
    
     hidemodal(){
    
         this.content="";
         this.showclass="";
          this.SpecialtysVal="";
       this.title="";
            this.submitted=false;
		  setTimeout(()=>{
           this.ishow=false; 
      },100)
         this.seletype="";
        document.body.classList.remove("modal-open");
    }
    isEmpty(value){
        return (value!=''&&value!=null)?value.replace(/\s+/g, ''):'';
    }
    
    
     

}
