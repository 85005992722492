export class Popup {
show: boolean;
    html:string;
    hr:string;
    size:string;
constructor(init?: Partial<Popup>) {
        Object.assign(this, init);
    }
    
}
