import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd,ActivatedRoute,NavigationStart,RouteConfigLoadEnd } from '@angular/router';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { Title,Meta } from '@angular/platform-browser';
import { StorageService } from './service/storage.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute,private http: HttpClient,private jwtHelper: JwtHelperService,private metaTagService: Meta, private store:StorageService) {
      
    }

  ngOnInit() {
     this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        
        return;
      }
        
         if(event instanceof NavigationStart) {
    }
      window.scrollTo(0, 0);
    });
  }
    

}
