<app-header
  [navbarBrandRouterLink]="['/']"
  [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/logo.svg', width: 113, height: 31, alt: 'getback'}"
  [navbarBrandMinimized]="{src: 'assets/img/logo_sm.svg', width: 30, height: 30, alt: 'CoreUI Logo'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="''" [asideMenuToggler]=false
[mobileAsideMenuToggler]=false>
  
  <ul class="nav navbar-nav ml-auto">
  
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
        <i class="icon-user icons font-2xl"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Account</strong></div>
        
        <a class="dropdown-item" href="#"><i class="fa fa-user"></i> Profile</a>
     <div class="divider"></div>
     
        <a class="dropdown-item" href="#"><i class="fa fa-lock"></i> Logout</a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized" (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
   <!-- <app-sidebar-minimizer></app-sidebar-minimizer>-->
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
  <div class="container-fluid p-0">
     <div class="row ">
     <div class="col-md-12">
    <cui-breadcrumb> </cui-breadcrumb>
          </div>
        


         </div>
     </div>
  
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>

</div>
<app-footer class="justify-content-center">
  <span>&copy; {{currentYear}}  <a href="#" class="fw-700"><span class="text-dark">get</span>back</a> .</span>
 
</app-footer>
<app-rightmodal></app-rightmodal>
