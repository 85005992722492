import { Component,OnInit,ViewEncapsulation } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, NavigationEnd,ActivatedRoute,NavigationStart,RouteConfigLoadEnd } from '@angular/router';
import { CommonModule }  from '@angular/common';
import { first } from 'rxjs/operators';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import { StorageService } from '../../service/storage.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
    styleUrls: ['../../../scss/vendors/toastr/toastr.scss','../../../../node_modules/ladda/dist/ladda-themeless.min.css'],
     encapsulation: ViewEncapsulation.None
})
export class LoginComponent  {
  
     cliniclist:Array<any[]>=[];
    selectedClinic={Name:"",Id:""};
    isAdmin:Number=0;
    
constructor(private route: ActivatedRoute,
        private router: Router,private store:StorageService,private jwtHelper: JwtHelperService,private http: HttpClient){
    
               var isClinicStore=null;
       this.store.getItem("clinic").subscribe(pa=>{
                 isClinicStore=pa;
                 });
    
      this.router.events.subscribe((val) => {
       
       if(val instanceof NavigationEnd){
           
          this.route
      .queryParams
      .subscribe(params => {
  
                  if(params["auth"]==undefined && isClinicStore==null ){
           
       }else{
            
           if(params.auth && !isClinicStore){
               this.store.setItem("clinic",params["auth"]);
                this.checkIfvalidToken();
                } else if(params.auth && isClinicStore){
                       if(params.auth!=isClinicStore){
                 this.store.removeItem("reportauth");
                           
               this.store.setItem("clinicid",params["auth"]);  
               this.store.setItem("clinic",params["auth"]);  
                 this.checkIfvalidToken();
               }else{
              
               } 
                          }else{
                              
                   
                          }
             
            
            };
               
                
         }); 
         
       }
    });
    
}
    
      ngOnInit() {
this.cliniclist=[];
          this.getClinicData().then(rdata=>{
              this.isAdmin=parseInt(rdata[3]);
          });
      this.http.get("https://api.getback.health/api/ClinicDetails").subscribe(clinicdata=>{
for(var i in clinicdata){
      this.cliniclist.push(clinicdata[i]); 
  }
  });
         
  }
        loadclinic(event){
      if(this.selectedClinic.Id!=""){
          
       
          var selindex=this.selectedClinic;
           
           this.getClinicData().then(cldata=>{
                  var clinicd="";
               clinicd=cldata[0]+"%%"+cldata[1]+"%%"+selindex.Id+"%%"+cldata[3];
               
               this.store.removeItem("clinicid");
               setTimeout(()=>{
                 
                     this.store.setItem("clinicid",btoa(clinicd).toString());  
               },20);
           
              
           });
               
         }
   
      
  }
      getClinicData(){
        
        return new Promise((resolve,reject)=>{
             var clinic= localStorage.getItem("clinic");
        if(clinic){
         var clinicdecode= atob(clinic).split("%%");
          resolve(clinicdecode);
            
        };
            
        });
        
    }
    
    checkIfvalidToken(){
       var ifhastoken=localStorage.getItem("reportauth");
      if (this.jwtHelper.isTokenExpired(ifhastoken)) {
   this.getNewToken();
  }else{
     
  }
             
     
    }
    
  
    getNewToken(){
        this.getClinicData().then(clinidata=>{
            
           this.http.post("https://api.getback.health/api/UserAuth",{
        "UserName": clinidata[0],
        "PublicKey": clinidata[1],
        "IsAdmin":parseInt(clinidata[3]) 
}).subscribe(data=>{
        if(data["token"]){
           localStorage.setItem("reportauth",data["token"]);
           localStorage.setItem("clinicdata",JSON.stringify(data));
        if(parseInt(clinidata[3])==1){
          // this.router.navigate(['/admin/clinicselect'])    
           }else{
          //    this.router.navigate(['/google/ads'])     
           }
         location.reload();
           }
          
      });
        });
        
          
    }
  
  
}
